/**
 * CarouselBannerMobile
 *
 * Provides for making the entire slide clickable when screen width is < 768px.
 * Author: Thomas Nicolosi
 *
 */
class CarouselBannerMobile {
  /**
   * static mobileClick
   *
   * @param {string} slideSelector - Selector for each slide element.
   * @param {string} linkSelector - Selector for anchor within each slide.
   */
  static mobileClick(
    slideSelector = ".js-dp-banner--item",
    linkSelector = ".js-dp-banner--item--button"
  ) {
    const banners = document.querySelectorAll(slideSelector);
    [...banners].forEach(banner => {
      const anchor = banner.querySelector(linkSelector);
      banner.onclick = e => {
        if (window.innerWidth > 768) {
          return e.stopPropagation();
        }
        window.location = anchor.getAttribute("href");
      };
    });
  }
}

const mobileBannerClick = CarouselBannerMobile.mobileClick;

export { mobileBannerClick };
