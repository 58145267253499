export const showOrHideNav = (event, slick, nextSlide) => {
  // hide or show the prev and next arrows depending upon width of window and which slide we're on
  if (document.body.clientWidth < 768) return;

  if (
    slick.currentSlide === slick.slideCount - 2 &&
    document.body.clientWidth > 1024
  ) {
    slick.$slider.find(slick.$nextArrow).hide();
  } else if (
    slick.currentSlide === 1 ||
    (document.body.clientWidth > 1279 && slick.currentSlide === 2)
  ) {
    slick.$slider.find(slick.$prevArrow).hide();
  } else {
    slick.$slider.find(slick.$nextArrow).show();
    slick.$slider.find(slick.$prevArrow).show();
  }
};

const BREAKPOINTS = {
  XL: 'xl',
  LG: 'lg',
  MD: 'md',
  SM: 'sm'
}

const getBreakPoint = width => {
  if ( width >= 1280 ) {
    return BREAKPOINTS.XL;

  } else if ( width >= 1024 ) {
    return BREAKPOINTS.LG;

  } else if ( width >= 768 ) {
    return BREAKPOINTS.MD;

  } else {
    // width < 768
    return BREAKPOINTS.SM;
  }
}

const WIDTH_TYPE_AUTO = {
  SET: 'set',
  UNSET: 'unset'
}

const LIST_DISPLAY_STYLE = {
  SELECTOR: '.slick-list .slick-track',
  CLASS_NAME: 'js-home__slick-track--auto'
}

const setSlideAutoWidth = (sliderSelector, type) => {
  if ( type === WIDTH_TYPE_AUTO.SET ) {
    return (
      $(sliderSelector)
        .find(LIST_DISPLAY_STYLE.SELECTOR)
        .addClass(LIST_DISPLAY_STYLE.CLASS_NAME)
    );
  } else if ( type === WIDTH_TYPE_AUTO.UNSET ) {
    return (
      $(sliderSelector)
        .find(LIST_DISPLAY_STYLE.SELECTOR)
        .removeClass(LIST_DISPLAY_STYLE.CLASS_NAME)
    );
  } else {
    return;
  } 
}
 

export const slideDisplay = selector => {
  
  const updateSlideDisplay = (event, slick) => {
    // Get the page width and breakpoint
    const pageWidth = document.body.clientWidth;
    const currentBreakPoint = getBreakPoint(pageWidth);
  
    const numberOfSlides = slick.slideCount;
  
    const maxSlidesToShow = slick.options.slidesToShow;
  
    // Return early if there are enough slides to fill
    //  largest container
    if ( numberOfSlides >= maxSlidesToShow ) {
      return setSlideAutoWidth(selector, WIDTH_TYPE_AUTO.UNSET);
    }
    
    // switch on breakpoints
    switch ( currentBreakPoint ) {
  
      case BREAKPOINTS.XL: 
        switch ( numberOfSlides ) {
          case 3:
          case 2:
          case 1:
            return  setSlideAutoWidth(selector, WIDTH_TYPE_AUTO.SET);
          default:
            return setSlideAutoWidth(selector, WIDTH_TYPE_AUTO.UNSET);
        }
  
      case BREAKPOINTS.LG:
        switch ( numberOfSlides ) {
          case 2:
          case 1:
            return  setSlideAutoWidth(selector, WIDTH_TYPE_AUTO.SET);
          default:
            return setSlideAutoWidth(selector, WIDTH_TYPE_AUTO.UNSET);
        }
  
        case BREAKPOINTS.MD:
          switch ( numberOfSlides ) {
            case 1:
              return setSlideAutoWidth(selector, WIDTH_TYPE_AUTO.SET);
            default:
              return  setSlideAutoWidth(selector, WIDTH_TYPE_AUTO.UNSET);
          }
  
        case BREAKPOINTS.SM:
          switch ( numberOfSlides ) {
            default:
              return setSlideAutoWidth(selector, WIDTH_TYPE_AUTO.UNSET);
          }
  
      default:
        return;
    }
  }

  return updateSlideDisplay;

}
