import breadcrumbClean from '../utils/breadcrumb-clean';

(function ($) {
    $(function () {
        /**
         * toggleNavMenu - Gives user the ability to manually collapse the navigation.
         *      .collapse() is a Bootstrap framework method.
         */
        function toggleNavMenu () {
            const isVisible = $('.js-site-sidebar-nav').filter(':visible').length > 0 
                ? true
                : false;
            if (isVisible) {
                $('.js-site-sidebar-nav').addClass('js-user__navbar--hide');
                $('.js-site-sidebar-nav').collapse('hide');
            } else {
                $('.js-site-sidebar-nav').removeClass('js-user__navbar--hide');
                $('.js-site-sidebar-nav').collapse('show');
            }
        }
        $('.js-site-header__btn--nav-toggle').on('click', toggleNavMenu);

        // Resets the behavior of the navigation.
        function resetToggleNav () {
            $('.js-site-sidebar-nav')
                .removeClass('js-user__navbar--hide')
                .removeClass('show');
        }

        // Reset the navigation when window resizes.
        let windowWidth = $( window ).width();
        const resizeReset = () => {
            const newWindowWidth = $( window ).width()
            if ( windowWidth !== newWindowWidth ) {
                windowWidth = newWindowWidth;
                resetToggleNav();
            }
        }
        function showSubNav () {
            const subNav = document.querySelector('.main-nav__subnav');
            if ( !subNav ) {
                return;
            }
            const activeSubNavItems = subNav.querySelectorAll('.nav-link.active');
            if ( activeSubNavItems.length > 0 ) {
                Array.prototype.forEach.call(activeSubNavItems, element => {
                    const subNavList = element
                        .parentNode
                        .parentNode;
                    subNavList
                        .classList.add('show');
                    subNavList
                        .parentNode
                        .querySelector('.nav-link__subnav')
                        .setAttribute('aria-expanded', 'true');
                });
            }
        }
        showSubNav();
        // Clean breadcrumbs
        breadcrumbClean('resources');
        $( window ).on('resize', resizeReset);
    })
}(jQuery));
