import axios from 'axios';
import queryString from 'query-string';
import $ from 'jquery';

// Description: Adds an icon to the nav bar which indicates if alerts are present.
//
// Author: Thomas Nicolosi
//
// On page load a REST service is called to retrieve alerts in an array.
//  If the length of the array is zero the icon fill color is gray,
//  indicating that there are no alerts present. 
//
// If length of the alerts array is greater than zero the length of the array
//  (which corresponds to the number of alerts) is displayed above the
//  alerts icon. The color of the icon is blue.
//
// A click handler attached to the icon allows for the display of the alerts in a list below the icon.
//  Each list item displays information regarding the SDK concerning the alert.
//
//  Clicking on the text of an alert will cause navigation to the corresponding SDK details page.
//      The url for the SDK details page includes query string parameters for the alert Id and VersionNumber.
//      On page load of the SDK details page the REST service is called which deletes the corresponding alert.
//      Upon success a fresh list of alerts is delivered and the laerts icon and count 
//      are displayed in the nav bar as described above.
//
// A transparent screen is rendered behind the list. Clicking on the screen or on the alert icon closes the list.
//
// A dismiss click target within each alert in the list allows the user to remove an alert from the list.
//  When the target is clicked a spinner indicates that removal is in progress.
//  Removal of an alert is accomplished by sending a request to the REST service 
//  containing the alert ID and VersionNumber as described above. 
//      Upon success a fresh list of alerts is returned from the service and the spinner is removed.
//      If the length of the alerts list returned is greater than zero the fresh list is rendered and
//      the new count is displayed as described aboved. Otherwise the alerts icon is displayed with fill color
//      set to gray and no count present.
//      If the service returns an error the spinner is removed and a failure message is displayed to indicate
//      that the alert was not removed from the list.


(function () {
    const pac_hangar_alerts = {

        // App State

        alerts: [],
        alerts_list_is_visible: false,

        // Constants

        constants: {
            COLORS: {
                HAS_ALERTS: "#0f53a5",
                NO_ALERTS: "#5b6478"
            },
            END_POINTS: {
                ALERTS: '/customapi/alerts/getusernewalert',
                ALERTS_DELETE: '/customapi/alerts/userread'
            }
        },

        // Element references

        alert_list: undefined,              // The list of alerts displayed.
        alert_count_display: undefined,     // The alert count display count.
        alert_wrapper: undefined,           // Wraps the alert icon and the count.
        alert_outer_wrapper: undefined,     // The app container
        alert_icon: undefined,              // The alert icon.
        app: undefined,                     // Entry point to the DOM

        // Component factories

        app_container: function () {

            // Create the element and add classes.

            let el = document.createElement('div');
            const classes = "alerts-wrapper js-alerts-wrapper h-100 d-flex flex-column justify-content-center mx-2";
            el = pac_hangar_alerts.add_classes(el, ...(classes.split(" ")));

            // Add the icon/counter

            el.innerHTML = pac_hangar_alerts.app_icon();

            return el;
        },

        app_icon: function (fillColor = pac_hangar_alerts.constants.COLORS.NO_ALERTS, width = 24, height = 24) {

            // Create the icon as a string.

            const el = `
                <div class="alerts-icon-wrapper js-alerts-icon-wrapper position-relative">
                    <a class="text-decoration-none js-alerts--collapse-control" href=".js-alerts--collapse" data-toggle="collapse" role="button" data-expanded="false" aria-controls="js-alerts--collapse">
                        ${pac_hangar_alerts.app_alert_count()}
                        <svg
                        class="js-alerts--icon"
                        height="${height}"
                        width="${width}"
                        fill="${fillColor}"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512">
                            <path d="M256 456c17.602 0 32.21-13.061 34.644-30h-69.288c2.434 16.939 17.042 30 34.644 30zM381 211c0-53.594-47.445-104.522-105.763-113.527A5 5 0 0 1 271 92.532V71c0-8.271-6.729-15-15-15s-15 6.729-15 15v21.531a4.999 4.999 0 0 1-4.237 4.941C176.468 106.783 131 159.889 131 221v155h250V211zM386 386H126c-8.271 0-15 6.729-15 15s6.729 15 15 15h260c8.271 0 15-6.729 15-15s-6.729-15-15-15z"/>
                        </svg>
                    </a>
                </div>
            `;

            return el;
        },

        app_alert_count: function () {

            // Determine visibility

            const class_list = `alerts--count js-alerts--count text-white bg-danger rounded-circle position-absolute text-center${(
                pac_hangar_alerts.alerts.length
                ? ""
                : " d-none"
            )}`;

            // Create the counter using a string literal.

            const alert_count = `
                <div class="${class_list}">
                    <div class="alerts--count-inner w-100 h-100 position-relative">
                        <span class="alerts--count-text js-alerts--count-text d-block position-absolute">
                            ${pac_hangar_alerts.alerts.length}
                        </span>
                    </div>
                </div>
            `;

            return alert_count;
        },

        app_alerts_list: function () {

            // Container and wrapper for the alerts list.

            let wrapper = document.createElement('div');

            const wrapper_classes = (
                pac_hangar_alerts.alerts_list_is_visible
                ? "card alerts--list-wrapper js-alerts--list-wrapper js-alerts--collapse position-absolute collapse show" 
                : "card alerts--list-wrapper js-alerts--list-wrapper js-alerts--collapse position-absolute collapse"
            );

            wrapper = pac_hangar_alerts.add_classes(wrapper, ...(wrapper_classes.split(" ")));

            // Add handlers for toggle of list.

            $(wrapper).on('shown.bs.collapse', pac_hangar_alerts.handle_list_toggle);
            $(wrapper).on('hidden.bs.collapse', pac_hangar_alerts.handle_list_toggle);

            let header = document.createElement('div');
            const header_text = document.createTextNode("Alerts");
            header.appendChild(header_text);
            const header_classes = "card-header alerts--wrapper-header text-center";
            header = pac_hangar_alerts.add_classes(header, ...(header_classes.split(" ")));

            let list = document.createElement('ul');
            const classes = "alerts--list js-alerts--list list-group list-group-flush";
            list = pac_hangar_alerts.add_classes(list, ...(classes.split(" ")));

            wrapper.appendChild(header);
            wrapper.appendChild(list);

            return wrapper;
        },

        app_alerts_screen: function () {

            // Acts as a background click target to close the alerts list.

            let screen = document.createElement('div');
            const screen_classes = "alerts--list-screen js-alerts--collapse collapse position-fixed";
            screen = pac_hangar_alerts.add_classes(screen, ...(screen_classes.split(" ")));
            screen.addEventListener("click", pac_hangar_alerts.handle_screen_click);
            return screen;
        },

        app_alerts_list_item: function () {
            let list_item = document.createElement('li');
            const classes = "list-group-item alerts--list-item position-relative";
            list_item = pac_hangar_alerts.add_classes(list_item, ...(classes.split(" ")));
            return list_item;
        },

        // Handlers

        handle_delete_alert_inline: function (event) {

            // Handles the dismiss icon click within the list.

            event.preventDefault();
            
            // Indicate that removal of alert is in progress.

            const loader = event.target.parentNode.querySelector(".js-alerts--dismiss-loader-wrapper");
            loader.classList.remove("d-none");

            // Delete the alert

            const alert_id = event.target.getAttribute("data-alertid");
            const version_number = event.target.getAttribute("data-version-number");
            const delete_alert = pac_hangar_alerts.delete_alert(alert_id, version_number);

            delete_alert.then(

                //  Upon success remove alert from UI.

                remove_alert

            ).then(
              
                // Upon success also update the alert count
                //  and update the application state.

                update_alert_count

            ).catch(

                // Fail gracefully.

                handle_failure
            );

            // 

            function remove_alert (response) {

                // For inline delete 

                const alerts = response.data;

                if (Array.isArray(alerts)) {

                    // Write the alerts to the application state
                    //  and update the UI.

                    pac_hangar_alerts.set_alerts(alerts);

                } else {

                    throw new Error('Alerts response is not an array.');
                }

                return response;
            }

            function update_alert_count (response) {
                pac_hangar_alerts.alerts = response.data;

                if (Array.isArray(pac_hangar_alerts.alerts) && pac_hangar_alerts.alerts.length > 0) {

                    // Update the count

                    pac_hangar_alerts.alert_count_display.innerHTML = pac_hangar_alerts.alerts.length;

                } else if (pac_hangar_alerts.alerts.length === 0) {

                    // Set the alerts to 0.

                    pac_hangar_alerts.alert_count_display.innerHTML = 0;

                    // Hide the alert count.

                    pac_hangar_alerts.alert_count_display.parentNode.parentNode.classList.add('d-none');
                    
                    // Hide the list and arrow.

                    pac_hangar_alerts.close_alerts_list();

                    // Remove the alert list.
                    
                    pac_hangar_alerts.alerts_list.parentNode.parentNode.removeChild(pac_hangar_alerts.alerts_list.parentNode);

                    // Change the icon color.

                    pac_hangar_alerts.alert_icon.setAttribute('fill', pac_hangar_alerts.constants.COLORS.NO_ALERTS);

                    // Remove the open list click handle hook.
                    pac_hangar_alerts.alert_icon.parentNode.setAttribute('href', '#');

                }
            }

            function handle_failure (error) {

                // Create the error container and message.

                let error_container = document.createElement('p');
                const classes = "alerts--error text-danger"
                error_container = pac_hangar_alerts.add_classes(error_container, ...classes.split(" "))
                const error_text = `Unable to remove alert. ${error.message}`;
                error_container.appendChild(document.createTextNode(error_text));

                // Hide the loader.

                loader.classList.add("d-none");

                // Append the message.

                event.target.parentNode.parentNode.appendChild(error_container);
            }

        },

        handle_screen_click: function () {    
            if (pac_hangar_alerts.alerts && pac_hangar_alerts.alerts.length > 0) {

                // Do not toggle if the list is empty.
                //  Prevents edge case that shows arrow after last alert has been removed.

                pac_hangar_alerts.close_alerts_list();
            }
        },

        handle_list_toggle: function (event) {
            
            // event.namespace === bs.collapse
            // event.type === shown or hidden

            if (event.type === 'shown') {
                pac_hangar_alerts.alerts_list_is_visible = true;
            }
            if (event.type == 'hidden') {
                pac_hangar_alerts.alerts_list_is_visible = false;
            }
        },

        // Methods

        add_classes: function (el, ...args) {
            if (el.classList) {
                Array.prototype.map.call(args, (arg) => el.classList.add(arg));
            }
            return el;
        },

        close_alerts_list: function () {    
            const collapsable_elements = document.querySelectorAll('.js-alerts--collapse');
            Array.prototype.forEach.call(collapsable_elements, function (element) {
                $(element).collapse('hide');
            });
            pac_hangar_alerts.alerts_list_is_visible = false;
        },

        delete_alert: function (alert_id, version_number) {

            // Calls service to delete alert and returns axios object.

            if (!alert_id) {
                return;
            }

            const url = `${pac_hangar_alerts.constants.END_POINTS.ALERTS_DELETE}?SDKid=${alert_id}&VersionNumber=${version_number}`; 

            return axios.get(url);
        },

        get_alerts: function () {

            // Gets the alerts and sets them in the app.

            axios.get(
                pac_hangar_alerts.constants.END_POINTS.ALERTS
            ).then(
                function (response) {
                    const {
                        data = []
                    } = response;

                    pac_hangar_alerts.set_alerts(data);
                }
            ).catch(
                (error) => console.log('there was an error fetching alerts', error)
            );
        },

        populate_alerts_list: function () {
            const list_container = pac_hangar_alerts.alerts_list;

            // Create a list item.
            const list_item = pac_hangar_alerts.app_alerts_list_item();

            const make_list = function (alert) {
                const item = list_item.cloneNode();

                const {
                    Id,
                    DownloadURL,
                    Platform,
                    ReleaseDate,
                    Title,
                    VersionNumber,
                } = alert;

                const calendar_month = [
                        'Jan',
                        'Feb',
                        'Mar',
                        'Apr',
                        'May',
                        'Jun',
                        'Jul',
                        'Aug',
                        'Sep',
                        'Oct',
                        'Nov',
                        'Dec'
                    ];

                let published_date = "";

                if (ReleaseDate) {
                    const dateObj = new Date(ReleaseDate);
                    published_date = `<br>Published Date: ${dateObj.getDate()}-${calendar_month[dateObj.getMonth()]}-${dateObj.getFullYear()}.`;
                }

                const template = `
                    <div class="d-flex align-content-start">
                        <a 
                            class="alerts--item-link text-decoration-none" 
                            href="/sdk/sdk-details${DownloadURL}?alertid=${Id}&VersionNumber=${VersionNumber}" data-alertid="${Id}"
                        >
                            <p class="alerts--item-text card-text">
                                Latest <strong>${Title}</strong>, ${Platform} version ${VersionNumber}, is now available for download. ${published_date}
                            </p>
                        </a>
                        <a 
                            data-alertid="${Id}"
                            data-version-number="${VersionNumber}"
                            href="#" class="d-block js-alerts--item-dismiss text-decoration-none" 
                            title="Dismiss alert"
                        >
                            &CircleTimes;
                        </a>
                        <div 
                            class="alerts--dismiss-loader-wrapper js-alerts--dismiss-loader-wrapper position-absolute d-none"
                        >
                            <div class="alerts--dismiss-loader-wrapper-inner position-absolute">
                                <div class="alerts--dismiss-loader spinner-border text-dark" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                    </div>
                `;

                item.innerHTML = template;
                const delete_alert = item.querySelector('.js-alerts--item-dismiss');
                delete_alert.addEventListener("click", pac_hangar_alerts.handle_delete_alert_inline);

                list_container.appendChild(item);
            };

            if (pac_hangar_alerts.alerts && pac_hangar_alerts.alerts.length > 0 && list_container) {
                pac_hangar_alerts.alerts.forEach(make_list);
            }
        },

        set_alerts: function (alerts) {
            pac_hangar_alerts.alerts = alerts;
            pac_hangar_alerts.update_app();
        },

        update_app: function () {

            if (pac_hangar_alerts.alerts && pac_hangar_alerts.alerts.length > 0) {

                // Show the count markup and update the count.

                pac_hangar_alerts.alert_count.classList.remove('d-none');
                pac_hangar_alerts.alert_count_display.innerHTML = pac_hangar_alerts.alerts.length;

                // Set the icon color.

                pac_hangar_alerts.alert_icon.setAttribute('fill', pac_hangar_alerts.constants.COLORS.HAS_ALERTS);

                // Clear the alerts list.

                const alerts_list_container = document.querySelector('.js-alerts--list-wrapper');

                if (alerts_list_container) {
                    alerts_list_container.parentNode.removeChild(alerts_list_container);
                }

                // Append the list container and click screen.

                pac_hangar_alerts.alert_outer_wrapper.appendChild(pac_hangar_alerts.app_alerts_list());
                document.getElementsByTagName('body')[0].appendChild(pac_hangar_alerts.app_alerts_screen());

                // Save a reference to the list container.

                pac_hangar_alerts.alerts_list = pac_hangar_alerts.alert_outer_wrapper.querySelector('.js-alerts--list');

                // Fill the list.

                pac_hangar_alerts.populate_alerts_list();

            } else {

                if (!pac_hangar_alerts.alert_count.classList.contains('d-none')) {

                    // Hide the count markup if no alerts are present.

                    pac_hangar_alerts.alert_count.classList.add('d-none');
                }

                // Hide the list and list indicator.

                pac_hangar_alerts.close_alerts_list();

                // Set the icon color.

                pac_hangar_alerts.alert_icon.setAttribute('fill', pac_hangar_alerts.constants.COLORS.NO_ALERTS);
            }
        },

        // Initialization:

        init: function () {

            // Run the app only if the user is authenticated.

            if (window.USER_LOGGED_IN) {
                
                // Return early if the user is internal, because there are no alerts for internal users.

                // Note: For internal user the network username is returned from login-status.js 
                //  rather than email address and set to  USER_EMAIL.
                //  Thus using NOT RegExp for valid email address to identify internal user. 
                //  Conversely valid email address is returned for external user.
                // Reference for valid email address RegExp is from W3C:
                //  https://html.spec.whatwg.org/multipage/input.html#valid-e-mail-address

                const is_internal_user = Boolean(
                    (
                        USER_EMAIL &&
                        /panasonic.aero$/.test(USER_EMAIL)
                    ) ||
                    (
                        USER_EMAIL &&
                        !(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(USER_EMAIL))
                    )
                );

                if (is_internal_user) {
                    return;
                }

                // Get a reference to the container div.

                pac_hangar_alerts.app = document.querySelector('.js-alerts-app');

                // Append markup.

                try {

                    pac_hangar_alerts.app.appendChild(pac_hangar_alerts.app_container());

                } catch (error) {

                    console.log('Unable to initialize alerts.', error.message);
                    return false;

                }

                // Get references to the DOM elements.

                pac_hangar_alerts.alert_outer_wrapper = document.querySelector('.js-alerts-wrapper');
                pac_hangar_alerts.alert_wrapper = document.querySelector('.js-alerts-icon-wrapper');
                pac_hangar_alerts.alert_icon = pac_hangar_alerts.alert_wrapper.querySelector('.js-alerts--icon');
                pac_hangar_alerts.alert_count = document.querySelector('.js-alerts--count');
                pac_hangar_alerts.alert_count_display = pac_hangar_alerts.alert_count.querySelector('.js-alerts--count-text');

                // Check to see if there is alertid query string in the location object.

                const is_alertsid_querystring = Boolean(location.search && queryString.parse(location.search).alertid);

                // Get the alerts if there is no alertid query string

                if (!is_alertsid_querystring) {

                    pac_hangar_alerts.get_alerts();

                } else {

                    const query_string_parsed = queryString.parse(location.search);
                    const alert_id = query_string_parsed.alertid;
                    const version_number = query_string_parsed.VersionNumber;
                    const update_alerts = pac_hangar_alerts.delete_alert(alert_id, version_number);
                    update_alerts.then(function (response) {
                        const {
                            data = []
                        } = response;
    
                        pac_hangar_alerts.set_alerts(data);
                    }).catch((error) => console.log('There was an error fetching alerts:', error));
                }
            }
        }
    };

    window.addEventListener('load', pac_hangar_alerts.init);
}());