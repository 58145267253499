(function() {

    var NavSearch = (function(){

        var app, s;

        var init = function(settings) {
            s = settings;
            clearSearchField();
            listenForClick();
        }

        var listenForClick = function() {
            if(!s.clickElement) return;
            var listener = s.clickElement.addEventListener('click', function(e) {
                if(!s.outerElement.classList.contains('active')) {
                    revealElement();
                }
            })
        }

        var revealElement = function() {
            s.clickElement.style.display = "none";
            s.outerElement.classList.add('active');
            // Focus the input unless IE11.
            //  reference: https://stackoverflow.com/questions/21825157/internet-explorer-11-detection
            var isIE11 = !!window.MSInputMethodContext && !!document.documentMode;
            if ( !isIE11 ) {
                s.revealingElement.select();
            }

            setTimeout(function() {
                document.addEventListener('click', listenForOuterClick);
            }, 0);
        }

        var listenForOuterClick = function(e) {
            if($(e.target).closest('.site-body--header--search').length) return;

            hideElement();
            
            document.removeEventListener('click', listenForOuterClick)
        }

        var hideElement = function (e) {
            s.clickElement.style.display = "block";
            s.outerElement.classList.remove('active');
        }

        var clearSearchField = function () {
            s.revealingElement.value = "";
        }

        return {
            init: function (settings) {
                if (!settings) settings = this.settings;
                init(settings);
            },
            settings: {
                clickElement: null,
                outerElement: null,
                revealingElement: null
            }
        }
    }());

    NavSearch.init({
        revealingElement: document.querySelector('.site-body--header--search--input'),
        outerElement: document.querySelector('.site-body--header--search'),
        clickElement: document.querySelector('.site-body--header--search--reveal-trigger')
    });
})();