/**
 * May be used to clean the breadcrumb trail on an intermediate details page.
 *  Needed when details view is rendered on a separate page.
 *  Removes the href attribute from the dummy details page and
 *  allows addition of a class to the list item to change appearance of the link.
 *  
 *  @param {string} listPage - page where the list will be rendered.
 *  @param {string} detailPage - page where the detail will be rendered.
 *  @param {object} options - see below:
 *      @param {string} detailPageClass - class to be added to the breadcrumb link we will make inactive.
 *      @param {string} itemClass - class of the breadcrumb items
 *      @param {boolean} remove - this removes the list item containing the dummy details page.
 */
function breadcrumbClean (listPage, detailPage = '', options) {
    const optionsObj = options && typeof options === 'object' 
        ? options
        : {};

    const {
        detailPageClass = 'inactive',
        itemClass = 'breadcrumb-item',
        remove = false
    } = optionsObj;

    if (!listPage) {
        return;
    }
    // Build a selector for the breadcrumb links.
    const selector = detailPage 
        ?   `.${itemClass} a[href="/${listPage}/${detailPage}"]`
        :   `.${itemClass} a[href="/${listPage}"]`;

    // Get the list of breadcrumb items.
    const breadcrumbItem = document.querySelector(selector);
    if ( remove && breadcrumbItem ) {
        breadcrumbItem.parentNode.parentNode.removeChild(breadcrumbItem.parentNode);
    } else if ( breadcrumbItem ) {
        breadcrumbItem.removeAttribute('href');
        breadcrumbItem.parentNode.classList.add(detailPageClass);
    }
}

export default breadcrumbClean;
