/**
 * Shows and hides the login menu.
 */
(function ($) {
    const showLoginStatusMenu = e => {
        $('.js-dp-login-dropdown__menu, .js-dp-login-dropdown__link').addClass('show');
    } 
    const hideLoginStatusMenu = e => {
        $('.js-dp-login-dropdown__menu, .js-dp-login-dropdown__link').removeClass('show');
    } 
    $('.js-dp-login-dropdown__wrapper').hover(showLoginStatusMenu, hideLoginStatusMenu);
}(jQuery));
