/**
 * Uses detection of -ms-high-contrast to determine if browser is IE11 (<Edge)
 * 
 * references: 
 *  https://developer.mozilla.org/en-US/docs/Web/CSS/@media/-ms-high-contrast
 *  https://stackoverflow.com/questions/35485760/targeting-ie-11-in-css-not-working
 *  https://developer.mozilla.org/en-US/docs/Learn/Tools_and_testing/Cross_browser_testing/Feature_detection#matchMedia
 */ 
const isIE11 = () => {
    if ( 
        window.matchMedia("(-ms-high-contrast: none)").matches ||
        window.matchMedia("(-ms-high-contrast: active)").matches 
     ) {
         return true;
     }
     return false
}

export { isIE11 }