
import './article-whitepaper-article-list';
import { mobileBannerClick } from './carousel-banner-mobile';
import './home-article-carousel';
import './home-news-carousel';
import './news-article-list';
import './main-nav.js';
import './solution-list.js';
import './main-nav.js';
import "./password-message";
import "./navSearch.js";
import "./login-status.js";
import "./init-bs-popovers";
import "./alerts";


mobileBannerClick();
