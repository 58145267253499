import 'slick-carousel';
import 'slick-carousel/slick/slick.scss';

/**
 * SlickCarousel 
 * Makes use of slick jQuery plugin for carousel.
 * 
 * References:
 *  https://www.npmjs.com/package/slick-carousel
 *  https://www.jqueryscript.net/demo/Fully-Responsive-Flexible-jQuery-Carousel-Plugin-slick/
 * 
 * 
 * Note: This implementation requires jQuery to be loaded globally.
 * 
 * Usage:
 *  1) import { carousel } 
 *  2) carousel('.selector', {settings})
 */

const defaultSettings = {
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1
}

class SlickCarousel {
    /**
     * slickInit - static method initializes slick carousel.
     * 
     * @param {string} selector - An element selector to be used by jQuery.
     *  This is the container for the collection of items in the slide.
     * @param {object} settings - slick plugin settings.
     * @return {object}
     */
    static slickInit(selector = '.slider', settings = defaultSettings) {
        if(!window.jQuery) {
            return false;
        }
        jQuery(() => {
            jQuery(selector).slick(settings);
        });
    }
}

const carousel = (selector, settings) => SlickCarousel.slickInit(selector, settings);

export { carousel }
