import axios from "axios";
import moment from "moment";

(function() {
  var PasswordExpiry = (function(options) {
    const USER_DETAILS_URL = `/customapi/User/Details`;
    const LOCAL_MSG_DISMISSED_KEY = "password dismissed";

    const init = () => {
      if (!window.USER_LOGGED_IN || USER_EMAIL.indexOf('panasonic.aero') > -1 || USER_EMAIL.indexOf('@') < 0) return;

      const dismissedFromLocal = getMsgDismissedFromLocal();
      // has this already been dismissed by user?
      if (
        dismissedFromLocal &&
        dismissedFromLocal ===
          USER_NAME + "-" +
          moment()
            .dayOfYear()
            .toString()
      ) {
        return; // don't bother if message has been dismissed today
      }

      getDataFromAPI().then(data => {
        if (data === null) return;

        const { showReminder, passwordExpiry, message, email } = data;
        if (!showReminder) return; // drop out if reminder should not be shown;

        displayMessage(formatMessage({ passwordExpiry, message })).then(() => {
          registerCloseAlert(email);
        });
      });
    };

    const getDataFromAPI = () => {
      return axios
        .get(USER_DETAILS_URL)
        .then(({ data }) => {
          if (data === null) return null;
          return {
            showReminder: data.ShowPasswordReminder || false,
            passwordExpiry: data.PasswordExpiryDate || "",
            message: data.PasswordReminderMessage || ""
          };
        })
        .catch(error => {
          console.error(
            "Get password reminders from API failed (getExpDateFromAPI function): \n",
            error
          );
          return null;
        });
    };

    const formatMessage = ({ passwordExpiry, message }) => {
      return `
            <style>
                #password-expire-message {
                    border-radius: 0;
                    margin: 0;
                }
            </style>
            <div id="password-expire-message" class="alert alert-warning alert-dismissible fade show" role="alert">
                ${message}
                <button type="button" class="close" data-dismiss="alert" aria-label="Close" >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            `;
    };

    const displayMessage = message => {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          document.body.insertAdjacentHTML("afterbegin", message);
          resolve();
        }, 1000);
      });
    };

    const registerCloseAlert = (email) => {
      const closeButton = document.querySelector("#password-expire-message");
      closeButton.addEventListener("click", e => {
        setMsgDismissedToLocal(email);
      });
    };

    const setMsgDismissedToLocal = (email) => {
      localStorage.setItem(
        LOCAL_MSG_DISMISSED_KEY,
        USER_NAME + "-" +
        moment()
          .dayOfYear()
          .toString()
      );
    };

    const getMsgDismissedFromLocal = () => {
      return localStorage.getItem(LOCAL_MSG_DISMISSED_KEY);
    };

    return {
      init: init
    };
  })();

  PasswordExpiry.init();
})();
