import { carousel } from "../vendor/slick-carousel";
import { ellipsis } from "../vendor/multiline-ellipsis";
import { slideDisplay } from "../utils/carousel-controls";
import "slick-carousel";
import { isIE11 } from '../utils/browser-feature-detect';

const sliderSettings = {
  infinite: false,
  slidesToShow: 4,
  slidesToScroll: 1,
  respondTo: 'window',
  responsive: [
    {
      breakpoint: 1280,
      settings: {
        slidesToShow: 3
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

const carouselSelector = '.js-dp-home__news-carousel';

if ( isIE11() ) {
  sliderSettings.useTransform = false;
  sliderSettings.useTransition = false;
}

const articleCarousel = $(carouselSelector)
  // init hook
  .on('init', slideDisplay(carouselSelector))

  // Initialize the carousel.
  .slick(sliderSettings)

  // Callbacks
  .on('breakpoint', slideDisplay(carouselSelector));

// Set the ellipsis overflow.
ellipsis(".js-dp-home-news-card__paragraph-text--overflow");
